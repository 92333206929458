<template>
  <div class="checkout flex flex-wrap w-full text-center justify-center">
    <div>
      Thank you for your willingness to give.<br />Please enter the amount in
      the text box below and press the button to be directed to the bank website
      to pay by credit card.
      <br />
      شكراً على رغبتك في العطاء<br />برجاء إدخال المبلغ ثم الضغط باسفل للتوجه
      لموقع الدفع عن طريق بطاقة الإئتمان
    </div>
    <div class="flex-wrap w-full">
      <p style="font-size: 0.8em; padding: 0px; margin: 0px">
        * If your credit card base currency is not Egyptian Pound,
        <a target="_blank" href="exchangerates">click here</a>
      </p>
      <p
        dir="rtl"
        style="text-align: center; font-size: 0.8em; padding: 0px; margin: 0px"
      >
        *
        <a target="_blank" href="exchangerates">إضغط هنا</a>
        إن كانت العملة الأساسية لحسابك مختلفة عن الجنيه المصري
      </p>
    </div>
    <div class="flex flex-wrap w-full mt-4">
      <table align="center" cellpadding="10">
        <tr>
          <td>
            <h4>Amount (EGP)*</h4>
          </td>
          <td>
            <input id="input_amount" type="text" v-model="amount" />
          </td>
          <td>
            <h4>*(المبلغ (جنيه مصري</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>Purpose</h4>
          </td>
          <td>
            <select v-model="selectedPurpose">
              <option
                v-for="purpose in purposes"
                :key="purpose.id"
                :value="purpose.id"
              >
                {{ purpose.name }}
              </option>
            </select>
          </td>
          <td>
            <h4>الغرض</h4>
          </td>
        </tr>
      </table>
    </div>
    <div class="flex justify-center items-center">
      <input
        type="checkbox"
        id="termsAccepted"
        name="termsAccepted"
        class="mx-2"
        v-model="termsAccepted"
      />
      <label for="termsAccepted">
        I accept the
        <a target="_blank" href="/users/terms-and-conditions"
          >Terms and Conditions</a
        >
        (أوافق على الشروط)
      </label>
    </div>
    <!-- Checkout Action -->
    <div class="flex flex-wrap w-full justify-center p-1 m-4">
      <div class="btn" @click="checkoutOrder">Click here to give | إضغط هنا للعطاء</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkout",
  created: function () {
    let self = this;
    fetch("/purposes/list")
      .then((data) => data.json())
      .then((data) => (self.purposes = data));
  },
  data: function () {
    return {
      amount: 100, // Form input
      selectedPurpose: 1, // Form input
      checkout_session: undefined, // Creation session API call response
      merchant: '701352',
      order_description: "KDEC Giving Online Donation",
      order_identifier: Date.now() + "-donation",
      termsAccepted: false,
      purposes: [],
    };
  },
  props: {},
  methods: {
    createCheckoutSession: function () {
      console.log("Creating checkout session ...");
    },
    checkoutOrder: function () {
      let self = this;
      fetch(
        `/checkout/create-session?amount=${self.amount}&orderId=${this.order_identifier}&purpose=${this.selectedPurpose}`
      )
        .then((data) => data.json())
        .then((data) => (self.checkout_session = data))
        .then(() => console.log(self.checkout_session))
        .then(() => {
          window.Checkout.configure({
            order: {
              id: this.order_identifier,
              description: this.order_description
            },
            session: {
              id: this.checkout_session.session_id,
            },
            interaction: {
              merchant: {
                name: "KDEC",
                logo: "https://s3.amazonaws.com/kdec-public-assets/images/kdec-logo.png",
                email: "partners@kdec.net",
                address: {
                  line1: "7 Sheikh Rihan Street, Garden City",
                  line2: "Cairo, Egypt",
                },
              },
            },
          });

          console.log("Done configuring session!");
          console.log("Will show payment page now ...");

          window.Checkout.showPaymentPage();
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>