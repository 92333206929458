<template>
  <div class="payments-list flex flex-wrap w-full justify-center text-center">
    <div class="h5 w-full">
      Payments History
    </div>
    <div
      class="flex w-full justify-center"
      v-for="(payment, index) in payments"
      :key="index"
      data="payment"
    >
      {{ payment.amount }} EGP on {{ payment.created_at }} ({{ payment.user.email }})
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentsList",
  created: function () {
    console.log("Loading payments data ...");
    let self = this;
    fetch("/payments/user-list")
      .then((data) => data.json())
      .then((data) => (self.payments = data));
  },
  data: function () {
    return {
      payments: []
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>