<template>
  <div id="app" class="w-full flex flex-wrap justify-center text-center m-4">
    <Checkout v-if="showCheckout" />
    <PaymentsList v-if="showPaymentsList" />
  </div>
</template>

<script>
import PaymentsList from "./components/PaymentsList.vue";
import Checkout from "./components/Checkout.vue";

export default {
  name: "App",
  data: function () {
    return {
      showPaymentsList:
        document.querySelector("#state_showPaymentsList").value == "True",
      showCheckout:
        document.querySelector("#state_showCheckout").value == "True",
    };
  },
  created: function () {},
  methods: {},
  components: {
    PaymentsList,
    Checkout,
  },
};
</script>

<style>
</style>
